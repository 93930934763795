<template>
    <div>
        <b-card>
            <app-view-table :title="trans('employee',5)" :loading="loading_list || loadingListPlanner || loadingListAssignment" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :isDropdownMenu="false" :isSearch="true" :customColumns="customColumns" :key="refresh">
                <template v-slot:selected="cell">
                    <div class="h-100 d-flex align-items-center justify-content-center">
                        <b-form-group>
                            <b-form-checkbox v-model="id_person_data_main" :value="cell.data.item.id" class="custom-control-primary" style="margin-top:5px" size="md" @change="selectItem(cell.data.item, 1)" :class="id_person_data_main == cell.data.item.id ? 'custom-checkbox-checked' : ''"/>
                        </b-form-group>
                    </div>
                </template>
            </app-view-table>
        </b-card>  
    </div>
</template>
<script>


import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';

export default {

    props: ["idsAssignmentDataMain", "person", "clearSelected", "startDate", "loadingListPlanner", "loadingListAssignment"],

    watch:{
        clearSelected(){
            this.clearSelectedItem();
        },
    },

    computed: {
        cPerson: {
            get() {return this.person},
            set(value) {this.$emit('update:person', value)}
        },

        cIdsAssignmentDataMain:{
            get() { return this.idsAssignmentDataMain },
            set(value) {this.$emit('update:idsAssignmentDataMain', value) }
        },

        cStartDate:{
            get() {return this.startDate},
            set(value) {this.$emit('update:startDate', value)}
        }
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            customColumns: ["selected"],
            id_person_data_main: null,
            refresh:0,
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {
                        functions: {type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        office_location: {type: 'list', changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-350'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
                ids_assignment_data_main: [],
                start_date: null
            },
            fields: [
                { key: "selected", label: "", tdClass: "py-0", visible: true  },
                { key: "name", label: this.trans('full-name',3), thStyle: { "min-width": "80px", width: "100%" }, visible: true},
                { key: "person_type", label: this.trans('agreement',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "types" },
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true,  filtering: true, filtering_name: "functions"},
                { key: "office_location_name", label: this.trans('branch',182), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true,  filtering: true, filtering_name: "office_location"},  
            ],
            items: [],
            firstLoad: 1,
            userSettings: new UserSettings(),
        };
    },


    methods: {

        selectItem(item, type = null) {        
             if(item.length == 0){
                return;
            }
            if(type == null){
                item = item[0];
            }

            var index = this.items.list.indexOf(item);
            for (var i=0; i<this.items.list.length; i++) {
                if(i != index){
                    this.items.list[i].selected = false;
                }else{
                    this.items.list[i].selected = true;
                }
            }
            
            this.cPerson = item;
            this.id_person_data_main = item.id;
        },

        getList: function() {
            this.id_person_data_main = null;
            this.loading_list = true;
            this.cPerson = null;

            axios
                .post("planning/assignments/person/searchForRegistration", {
                    filters: JSON.stringify(this.filters),
                    first_load: this.firstLoad
                })
                .then((res) => {
                    this.filters.page = res.data.filters.page;
                    this.items = res.data.items;
                    
                    if(this.firstLoad == 1){
                        this.filters.filtering.functions.values = res.data.filters.filtering.functions.values;
                        this.filters.filtering.office_location.values = res.data.filters.filtering.office_location.values;
                        this.filters.filtering.types.values = res.data.filters.filtering.types.values;
                    }
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
                
        },

        clearSelectedItem(){
            
            for (var i = 0; i < this.items.list.length; i++) {
                this.items.list[i].selected = false;
            }

            this.refresh++;
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 11);
        },

        async loadSettings() {
            this.loading_list = true;
            var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData != undefined && getData.addPersonToAssignments != undefined){
                var sFilters = getData.addPersonToAssignments;
                this.filters.sorting = sFilters.sorting;
                this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;
            }
            

            if(this.idsAssignmentDataMain == null || this.startDate == null){
                this.cIdsAssignmentDataMain = sFilters.ids_assignment_data_main;
                this.cStartDate = sFilters.start_date;
                this.filters.ids_assignment_data_main = sFilters.ids_assignment_data_main;
                this.filters.start_date = sFilters.start_date;
            }else{
                this.filters.ids_assignment_data_main = this.idsAssignmentDataMain;
                this.filters.start_date = this.startDate;
            }

             if(getData != undefined && getData.addPersonToAssignments != undefined){
                this.filters.filtering = sFilters.filtering;
             }

            
            this.saveSettings();
            this.getList();
        },

       onCreateThis() { 
            
            this.loadSettings();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.firstLoad = 0;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                
                if (!this.loading_list) {
                    this.firstLoad = 0;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.firstLoad = 0;
                    this.filters.page = 1;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.firstLoad = 0;
                    this.getList();
                    this.saveSettings();
                }   
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.firstLoad = 0;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.firstLoad = 0;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.office_location.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.firstLoad = 0;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.firstLoad = 0;
                    this.getList();
                }   
            });
        },
    }
 
};
</script>