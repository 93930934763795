<template>
    <div>
        <b-card>
            <b-overlay :show="loading_list" :opacity="0.6">
                <b-row>
                    <b-col start>
                        <div class="app-card-profile-title">{{trans('posting', 3)}} <span v-if="total && total > 0"> {{ total }} </span> <span v-if="person !== null"> - {{person.name}}</span></div>
                    </b-col>                     
                    
                    <b-col cols="auto" end class="d-flex align-items-center">

                        <div style="min-width:24px; max-width:24px; min-height: 26px">  
                                
                            <b-link v-if="!loading_list" style="color: #98A2B3; cursor:pointer; outline:none !important;" @click="checkPerson">
                                <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
                            </b-link>

                            <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
                        </div>
                    </b-col>
                </b-row>

                <b-row class="mt-3" v-if="show_padding">
                    <b-col start>

                        <b-list-group flush>
                            <!-- item -->
                            <b-list-group-item class="app-checklist-status-item " v-bind:class="{ 'app-checklist-danger':  item.registered == 1, 'app-checklist-success': item.registered == 0 && item.is_other_work == 0 && item.is_unavailability == 0, 'app-checklist-warning': item.registered == 0 && (item.is_other_work == 1 || item.is_unavailability == 1)}" v-for="item in cAssignmentsData" :key="item.id_assignment_data_main">
                                <b-row align-v="center" class="ml-1">
                                    <b-col>
                                        <b-row align-v="center">

                                            <b-col cols="3" class="ml-1 pl-0">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 class="mb-0 text-sm font-weight-600" >{{item.location_name}}</h4>
                                                        <span class="app-action-status-gray-text">{{item.departments}}</span>
                                                    </div>
                                                </div>
                                            </b-col>

                                            <b-col cols="3" class="ml-1 pl-0">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 class="mb-0 text-sm font-weight-600" >{{item.cao_name}}</h4>
                                                        <span class="app-action-status-gray-text">{{item.function_name}}</span>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col>
                                            </b-col>
                                            <b-col  cols="auto" end>
                                                <b-row>
                                                    <b-col >
                                                        <h4 class="mb-0 text-sm font-weight-600 text-right" >{{item.assignment_date_from}} - {{item.assignment_date_to}}</h4>
                                                        
                                                    </b-col>
                                                </b-row>
                                            </b-col>

                                        </b-row>

                                        <b-row align-v="center" class="mt-1" v-if="item.registered == 1">
                                            <b-col cols="auto" class="p-0 pl-1">
                                                <span class="app-action-status-red-text">{{trans('as-flexworker-already-registered',216)}}</span>
                                            </b-col>
                                        </b-row>

                                        <b-row v-if="(item.registered == 0 && item.is_other_work == 1)">
                                            <b-col cols="12" class="pl-0 mb-0">
                                                <b-alert variant="warning" show class="mt-3 mb-0">
                                                    <div class="text-md-start">{{trans('as-flexworker-already-has-planned-work',216)}}</div> 
                                                </b-alert> 
                                            </b-col>
                                        </b-row>

                                        <b-row v-if="(item.registered == 0 && item.is_unavailability == 1)">
                                            <b-col cols="12" class="pl-0 mb-0">
                                                <b-alert variant="warning" show class="mt-3 mb-0">
                                                    <div class="text-md-start">{{trans('mb-rules-unavailability',171)}}</div> 
                                                </b-alert> 
                                            </b-col>
                                        </b-row>
                                        
                                    </b-col>

                                </b-row>
                                
                            </b-list-group-item>

                        </b-list-group>

                    </b-col>
                </b-row>

            <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </b-card>

    </div>
</template>
<script>


import axios from "@axios";
import moment from "moment";

export default {
    components: { 

    },

    props: ["idsAssignmentDataMain", "person", "assignmentsData", "loading", "planningParametersCheck", "loadingListAssignment"],

    computed: {
        cAssignmentsData: {
            get() { return this.assignmentsData },
            set(value) { this.$emit('update:assignmentsData', value) }
        },

        cPlanningParametersCheck: {
            get() { return this.planningParametersCheck },
            set(value) { this.$emit('update:planningParametersCheck', value) }
        },

        cLoading: {
            get() { return this.loading },
            set(value) { this.$emit('update:loading', value) }
        }
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            customColumns: ["selected"],
            loading_list: false,
            total: null,
            show_padding: false
        };
    },


    methods: {
        checkPerson: function() {
            if (this.idsAssignmentDataMain != null && this.idsAssignmentDataMain.length > 0) {
                this.loading_list = true;
                this.cLoading = true;
                this.$emit('update:loadingListAssignment', true);

                axios
                    .post("planning/assignments/person/checkPersonInAssigmentsWithData", {
                        ids_assignment_data_main: JSON.stringify(this.idsAssignmentDataMain),
                        id_person_data_main: this.person.id
                    })
                    .then((res) => {
                        this.cAssignmentsData = res.data;
                        this.total = res.data.length;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading_list = false;
                        this.cLoading = false;
                        this.show_padding = true;
                        this.$emit('update:loadingListAssignment', false);
                    });
            }
                
        },

        getDateAndHours(assignment_item) { 
            let start_hour = parseInt(assignment_item.hour_from.slice(0,2));
            let start_minutes = assignment_item.hour_from.slice(3);
            let end_hour = parseInt(assignment_item.hour_to.slice(0,2));
            let end_minutes = assignment_item.hour_to.slice(3);

            return start_hour + '<sup class="ml-1">' + start_minutes + '</sup> - '+ end_hour + '<sup class="ml-1">' + end_minutes + '</sup> ';
        },

        getDayAndWeek(assignment_item) {
            moment.locale('nl') 
            return moment(assignment_item.assignment_date).format('dddd') + ", wk " + moment(assignment_item.assignment_date).format('ww');
        },
   

        onCreateThis() { 
            this.checkPerson();


            this.$watch('person', function(newVal, oldVal) {
                if (newVal != null && !this.loading_list) {
                    this.checkPerson();
                }   
            });
        },
    }
 
};
</script>
<style scoped>

.app-local-width{
    min-width:24px; 
    max-width:24px;
}

.app-action-status-gray-text{
    font-size: 12px;
    color: #8898aa;
}

.app-action-status-red-text{
    font-size: 12px;
    color: #f04438 !important;
}

</style>