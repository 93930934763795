<template>
    <b-row>
        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1"> 
            <div class="mb-3">
                <div class="mt-3 mb-1 d-flex align-items-center">
                <router-link :to="getPath()">
                        <b-icon icon="arrow-left" class="mr-2"></b-icon>
                        <span v-if="$route.params.returnType == 'assignment_card'">{{trans('back-to-assignment',200)}}</span>
                        <span v-else-if="$route.params.returnType == 'registrations_list'">{{trans('to-registration-list',200)}}</span>
                        <span v-else>{{trans('back-to-job-offer',200)}}</span>
                </router-link>
                </div>
            </div>

            <b-row class="mb-3">
                <b-col>
                    <offers-assignments-person-add-search-component :idsAssignmentDataMain.sync="ids_assignment_data_main" :person.sync="person" :startDate.sync="start_date" :loadingListPlanner.sync="loading_list_planner" :loadingListAssignment.sync="loading_list_assignment" />
               </b-col>
            </b-row>
            
            <b-row class="mb-3" v-if="person != null">
                <b-col>
                    <planning-planner-person-add-schedule-component :person.sync="person" :startDate.sync="start_date" :fromListPersonAdd="assignments_data" :loadingListPlanner.sync="loading_list_planner"/>
                </b-col>
            </b-row>

            <b-row class="mb-3" v-if="person != null">
                <b-col>
                    <offers-assignments-person-add-assignments-list-component  :idsAssignmentDataMain="ids_assignment_data_main" :loading.sync="loading" :person.sync="person" :assignmentsData.sync="assignments_data" :loadingListAssignment.sync="loading_list_assignment" />
                </b-col>
            </b-row>

            <div class="mt-4 mb-3" >
                <app-button type="primary" @click="registerPerson()" :loading="loading" :disabled="person == null">
                    {{trans('planning-register',210)}}
                </app-button>
            </div>
        </b-col>
    </b-row>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import OffersAssignmentsPersonAddSearchComponent from './Components/OffersAssignmentsPersonAddSearchComponent.vue';
import PlanningPlannerPersonAddScheduleComponent from '@app/Planning/Planner/PlanningPlannerPersonAddScheduleComponent.vue';
import OffersAssignmentsPersonAddAssignmentsListComponent from './Components/OffersAssignmentsPersonAddAssignmentsListComponent.vue';

export default {
    components: {
        OffersAssignmentsPersonAddSearchComponent,
        PlanningPlannerPersonAddScheduleComponent,
        OffersAssignmentsPersonAddAssignmentsListComponent
    },

    watch: {
 
    },

    computed: {
        posible_click(){
           return this.assignments_data.filter(field => field.registered == 1 || field.person_offer_status == -1).length !=  this.assignments_data.length
        }
    
    },

    data(){
        return {
            ids_assignment_data_main: this.$route.params.ids_assignment_data_main,
            start_date: this.$route.params.start_date,
            assignments_data: [],
            person: null,
            alertClass: new AppAlerts(),
            client_confirmed: null,
            loading: false,
            loading_list_planner: false,
            loading_list_assignment: false,
        }
    },

    methods:{   
        
        registerPerson(){
            var bad_assignments = this.assignments_data.filter(field => field.registered == 1).length
            
            if(bad_assignments > 0){
                this.alertClass.openAlertWarning(this.trans('ap-cannot-add-person-to-secondments',216));
                return;
            }
            
            this.loading = true;

                    axios
                        .post('planning/assignments/person/register', {
                            assignments_data: JSON.stringify(this.assignments_data),
                            id_person_data_main: this.person.id
                        })
                        .then((res) => {                            
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            if(this.$route.params.returnType == 'assignment_card'){
                                this.$router.push({ name: "planning-assignments-profile", params: { id_assignment_data_main: this.$route.params.returnIdType } });
                            }else if(this.$route.params.returnType == 'registrations_list'){
                                this.$router.push({name: "planning-registrations", params: { } });
                            }else{
                                this.$router.push({ name: "planning-offers", params: { } });
                            }
                        });
        },

        getPath(){
            if(this.$route.params.returnType == 'assignment_card'){
                return '/planning/assignments/profile/' + this.$route.params.returnIdType;
            }else if(this.$route.params.returnType == 'registrations_list'){
                return '/planning/registrations';
            }else{
                return '/planning/offers';
            }
        }
    }
}
</script>

<style scoped>

</style>